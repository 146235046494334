import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { CheckIcon } from '@heroicons/react/outline';

import Button from 'components/Button';
import { isBrowser } from 'lib/devices';
import { downloadLink } from 'lib/videoproc';
import { trackPromotion } from 'lib/analytics';

export default function AppFeatures() {
  const { t } = useTranslation();

  const features = [
    t('videoproc:list.dl'),
    t('videoproc:list.conv'),
    t('videoproc:list.comp'),
    t('videoproc:list.edit'),
    t('videoproc:list.dvd'),
    t('videoproc:list.save'),
    t('videoproc:list.extract'),
    t('videoproc:list.ratio'),
    t('videoproc:list.adjust'),
    t('videoproc:list.record'),
  ];

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="pb-16 xl:flex xl:items-center xl:justify-between">
          <div>
            <h2 className="text-4xl font-extrabold sm:tracking-tight">
              <span className="text-gray-900">{t('videoproc:right_soft')}</span>
            </h2>
            <p className="mt-5 text-lg text-gray-500">
              {t('videoproc:right_soft_sub')}
            </p>
          </div>

          {isBrowser ? (
            <Button
              as="a"
              href={downloadLink}
              rel="nofollow"
              size="xl"
              color="blue"
              className="inline-flex mt-8 w-full sm:mt-10 sm:w-auto xl:mt-0"
              onClick={() => trackPromotion('btn-dl-right-soft')}
            >
              {t('videoproc:dl_free')}
            </Button>
          ) : (
            <Button
              size="xl"
              color="blue"
              className="inline-flex mt-8 w-full sm:mt-10 sm:w-auto xl:mt-0"
            >
              {t('videoproc:dl_free')}
            </Button>
          )}
        </div>
        <div className="border-t border-gray-200 pt-16 xl:grid xl:grid-cols-3 xl:gap-x-8">
          <div>
            <h3 className="text-base font-semibold text-blue-600 tracking-wide uppercase">
              {t('videoproc:everything')}
            </h3>
            <p className="mt-2 text-3xl font-extrabold text-gray-900">
              {t('videoproc:all_in_one')}
            </p>
            <p className="mt-4 text-lg text-gray-500">
              {t('videoproc:all_in_one_desc')}
            </p>
          </div>
          <div className="mt-4 sm:mt-8 md:mt-10 md:grid md:grid-cols-2 md:gap-x-8 xl:mt-0 xl:col-span-2">
            <ul className="divide-y divide-gray-200">
              {features.slice(0, 5).map((feature, featureIdx) =>
                featureIdx === 0 ? (
                  <li key={feature} className="py-4 flex md:py-0 md:pb-4">
                    <CheckIcon
                      className="flex-shrink-0 h-6 w-6 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-base text-gray-500">
                      {feature}
                    </span>
                  </li>
                ) : (
                  <li key={feature} className="py-4 flex">
                    <CheckIcon
                      className="flex-shrink-0 h-6 w-6 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-base text-gray-500">
                      {feature}
                    </span>
                  </li>
                )
              )}
            </ul>
            <ul className="border-t border-gray-200 divide-y divide-gray-200 md:border-t-0">
              {features.slice(5).map((feature, featureIdx) =>
                featureIdx === 0 ? (
                  <li
                    key={feature}
                    className="py-4 flex md:border-t-0 md:py-0 md:pb-4"
                  >
                    <CheckIcon
                      className="flex-shrink-0 h-6 w-6 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-base text-gray-500">
                      {feature}
                    </span>
                  </li>
                ) : (
                  <li key={feature} className="py-4 flex">
                    <CheckIcon
                      className="flex-shrink-0 h-6 w-6 text-green-500"
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-base text-gray-500">
                      {feature}
                    </span>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
