import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import SEO from 'components/SEO';
import Section from 'components/Section';
import Divider from 'components/Divider';
import ActionButtons from 'components/videoproc/ActionButtons';
import AppFeatures from 'components/videoproc/AppFeatures';
import { trackPromotion } from 'lib/analytics';

export default function VideoProcPage() {
  const { t } = useTranslation();

  return (
    <>
      <SEO
        title={t('videoproc:meta.title')}
        description={t('videoproc:meta.desc')}
        keywords={t('videoproc:meta.keywords')}
      >
        <meta name="robots" content="noindex" />
      </SEO>

      <Section>
        <div className="text-center">
          <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl">
            <a
              href="https://www.videoproc.com/video-converting-software/feature-online-vs-videoproc.htm?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo-home&ttref=stvideo-home"
              target="_blank"
              rel="nofollow noreferrer noopener"
              className="block xl:inline text-blue-600 underline"
              onClick={() => trackPromotion('txt-vp-title')}
            >
              VideoProc Converter
            </a>{' '}
            <span className="block xl:inline">{t('videoproc:title')}</span>
          </h1>
          <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
            {t('videoproc:subtitle')}
          </p>

          <ActionButtons />
        </div>
      </Section>

      <AppFeatures />

      <Divider />

      <Section>
        <div className="text-center">
          <h2 className="mt-1 text-4xl font-extrabold text-gray-900 sm:tracking-tight">
            {t('videoproc:ready')}
            <span className="block mt-4">{t('videoproc:start_free')}</span>
          </h2>
        </div>

        <ActionButtons className="mt-5 md:mt-8 mb-10" />

        <iframe
          width="800"
          style={{ height: '440px' }}
          src="https://www.youtube.com/embed/Z0OYJ7_6t4I"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="max-w-full mx-auto"
          title="Learn more about VideoProc"
        />
      </Section>
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "videoproc"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
