import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Button from 'components/Button';
import { isBrowser } from 'lib/devices';
import { downloadLink, licenseLink } from 'lib/videoproc';
import { trackPromotion } from 'lib/analytics';

export default function ActionButtons({ className = 'mt-5 md:mt-8' }) {
  const { t } = useTranslation();

  return (
    <div
      className={`sm:flex sm:justify-center space-y-3 sm:space-y-0 sm:space-x-8 ${className}`}
    >
      <div className="text-center">
        {/* Note: Wrapped button with div because of issues when hydrating in the browser */}
        <div>
          {isBrowser ? (
            <Button
              as="a"
              href={downloadLink}
              rel="nofollow"
              size="2xl"
              color="blue"
              className="w-full"
              onClick={() => trackPromotion('btn-dl-action')}
            >
              {t('videoproc:dl_free')}
            </Button>
          ) : (
            <Button size="2xl" color="blue" className="w-full">
              {t('videoproc:dl_free')}
            </Button>
          )}
        </div>
        <a
          href="https://www.videoproc.com/guide/tech-specs.htm?utm_source=savethevideo.com&utm_medium=referral&utm_campaign=stvideo&ttref=stvideo"
          rel="nofollow"
          className="text-gray-500 inline-block mt-1 hover:underline hover:text-gray-600"
          onClick={() => trackPromotion('txt-tech-specs')}
        >
          {t('videoproc:dl_subtext')}
        </a>
      </div>

      <div className="text-center">
        <Button
          as="a"
          href={licenseLink}
          rel="nofollow"
          size="2xl"
          className="w-full"
          onClick={() => trackPromotion('btn-license')}
        >
          {t('videoproc:get_full_license')}
        </Button>
        <a
          href={licenseLink}
          rel="nofollow"
          className="text-gray-500 inline-block mt-1 hover:underline hover:text-gray-600"
          onClick={() => trackPromotion('txt-upgrade')}
        >
          {t('videoproc:license_subtext')}
        </a>
      </div>
    </div>
  );
}
